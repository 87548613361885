import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { BlockKybInstance } from "./KybInstance";
import { ActiveView } from "./data";
import { AbstractUserBlockMethods } from "@/types/vue/AbstractUserBlockMethods";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";
import { isClaimsGateFunctionReturnedError } from "@/helpers/vue";

export async function nextButton(state: BlockKybInstance): Promise<void> {
  try {
    state.uiToggles.isProcessing = true;
    state.blockProps.tradingNameInput.state = null;
    state.blockProps.tradingNameSingleSelect.state = null;
    state.uiToggles.isCreateClaimError = false;
    if (state.activeView === "companyDetails") {
      console.log("companyDetails");
      if (!(await validateCompanyDetails(state))) {
        // Run these to set input state
        validateTradingNameIsDifferent(state);
        validateTradingName(state);
        state.uiToggles.isProcessing = false;
        return;
      }

      const companyStore: Record<string, string> = {
        tradingNameDiffersToRegistered: state.tradingNameIsDifferent,
      };

      if (!validateTradingNameIsDifferent(state)) return;

      if (state.tradingNameIsDifferent === "Yes") {
        if (!validateTradingName(state)) return;

        companyStore.companyTradingName = state.tradingName;
      }

      const hashedCompanyStore = await state.variablesService.hashData(companyStore, state.funnelVariables);

      state.claimDataService.setManyArtefacts(hashedCompanyStore);

      await state.claimDataService.update();

      state.activeView = ActiveView.uboDetails;
      state.uiToggles.isProcessing = false;
    } else if (state.activeView === "uboDetails") {
      const blockDataArray = state.$refs.BlockDataArray as unknown as AbstractUserBlockMethods;

      if (!(await blockDataArray.validations())) {
        state.uiToggles.isProcessing = false;
        return;
      }

      const uboData = (await blockDataArray.computes()) as unknown as Array<any>;

      const uboStore: Record<string, any> = {
        ubos: uboData,
      };

      state.ubos = uboData;

      const hashedUboStore = await state.variablesService.hashData(uboStore, state.funnelVariables);

      state.claimDataService.setManyArtefacts(hashedUboStore);

      await state.claimDataService.update();

      const result = await createSupportingClaims(state);

      if (isClaimsGateFunctionReturnedError(result)) {
        console.error(result.data);
        // Set error state
        state.uiToggles.isCreateClaimError = true;
        state.uiToggles.isProcessing = false;
        return;
      }

      if ((!result.data as any)?.success) {
        console.error(result.data);
        // Set error state
        state.uiToggles.isCreateClaimError = true;
        state.uiToggles.isProcessing = false;
        return;
      }

      /**
       * Backend would have updated user with new verification
       */
      await state.userDataService.refresh();

      await state.$store.dispatch("events/fire", { name: "next" });

      await state.$nextTick();
      state.uiToggles.complete = true;
      state.uiToggles.isProcessing = false;
    }
  } catch (error) {
    console.error(error);
    await state.infoModalService.fire(
      "error",
      {
        title: msg.errors.unexpectedError.title,
        text: msg.errors.unexpectedError.text,
        confirmButtonText: "Redirect",
      },
      { route: state.$route }
    );

    await state.$router.push({ name: "Claims" });
  }
}

async function validateCompanyDetails(state: BlockKybInstance): Promise<boolean> {
  const blockCompany = state.$refs.BlockCompany as unknown as AbstractUserBlockMethods;

  if (!(await blockCompany.validations())) {
    console.log("BlockCompany validations failed");
    return false;
  }

  if (!(await blockCompany.computes())) return false;

  return true;
}

function validateTradingName(state: BlockKybInstance) {
  if (state.tradingName === "") {
    state.blockProps.tradingNameInput.state = false;
    state.blockProps.tradingNameInput.invalidFeedback = "Please select an option";
    state.uiToggles.isProcessing = false;
    return false;
  }
  return true;
}

async function createSupportingClaims(state: BlockKybInstance) {
  const isTest = state.claimDataService.getArtefact("claimStatus") === "testing";
  const targetFunnelId = getTargetFunnelId(state);
  const data = {
    requester: { claimId: state.claimId, userId: state.userId, workspaceId: state.navigationWorkspaceId, isTest },
    targetFunnelId,
    claimsToCreate: [...getUbos(state)],
  };
  console.log("createSupportingClaims", data);

  return onCallGateway<"createSupportingClaims">({
    functionName: "createSupportingClaims",
    data,
  });
}

function getTargetFunnelId(state): string {
  const stewartsInteruptionFunnelId = "tFkNbUQT5qxZt7l9TrL1";
  if (state.funnelId === stewartsInteruptionFunnelId) {
    const stewartsUboKycFunnelId = "GiVYXHXJHchxLvfR6cKi";
    return stewartsUboKycFunnelId;
  } else {
    return "aREyPriXSdgjq6qSw9ve"; // Prod CG Testing supporting claim funnel
  }
}

/**
 * COnvert the UBO's stored in state into the format to be sent to the backend
 */
function getUbos(state): Array<{ user: { firstName: string; lastName: string; email: string } }> {
  const { ubosVariable } = state;
  const firstNameKey = ubosVariable.structure.find((key) => key.field === "firstName").id;
  const lastNameKey = ubosVariable.structure.find((key) => key.field === "lastName").id;
  const emailKey = ubosVariable.structure.find((key) => key.field === "email").id;
  return state.ubos.map((ubo) => {
    return {
      user: {
        firstName: ubo[firstNameKey],
        lastName: ubo[lastNameKey],
        email: ubo[emailKey],
      },
    };
  });
}

function validateTradingNameIsDifferent(state: BlockKybInstance): boolean {
  if (state.tradingNameIsDifferent === "") {
    state.blockProps.tradingNameSingleSelect.state = false;
    state.blockProps.tradingNameSingleSelect.invalidFeedback = "Please select an option";
    return false;
  }
  return true;
}
