<script>
import BlockPadding from "@/components/shared/blocks/padding.vue";
export default {
  name: "BlockImage",
  components: { BlockPadding },
  props: {
    source: {
      type: String,
      required: true,
      allowVariable: true,
      default:
        "https://images.unsplash.com/photo-1517976487492-5750f3195933?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
      description: "URL of the source",
    },
    width: {
      type: String,
      required: false,
      allowVariable: false,
      default: "auto",
      valid: ["auto", "32", "64", "128", "192", "256", "512"],
      description: "Set the image width",
    },
    align: {
      type: String,
      required: false,
      allowVariable: false,
      default: "center",
      valid: ["left", "center", "right"],
      description: "Aligns the image",
    },
    rounded: {
      type: String,
      required: false,
      allowVariable: false,
      default: "true",
      valid: ["true", "top", "right", "bottom", "left", "circle", "0"],
      description:
        "When set to 'true', makes the image corners slightly rounded. Can also be used to disable rounded corners or make the image a circle/oval",
    },
    fluid: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: true,
      description:
        "Makes the image responsive. The image will shrink as needed or grow up the the image's native width",
    },
    thumbnail: {
      type: Boolean,
      required: false,
      allowVariable: false,
      default: false,
      description: "While true adds a thumbnail border around the image",
    },
    /** @type { Props.Str} */
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    },
  },
  data() {
    return {
      left: false,
      center: false,
      right: false,
    };
  },
  methods: {
    getRounded() {
      if (this.rounded === "true") {
        return true;
      } else {
        return this.rounded;
      }
    },
    getAlign(align) {
      if (this.align === align) {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.imgBackground {
  min-height: 32px;
  min-width: 32px;
}
</style>
<template>
  <div class="imgBackground">
    <BlockPadding :padding="padding" />
    <b-img
      onerror="this.onerror=null;this.src='https://i.ibb.co/fGZnMR0/1000-x-500-Landing-page-images-4.png';"
      :src="source ? source : 'https://i.ibb.co/fGZnMR0/1000-x-500-Landing-page-images-4.png'"
      :fluid="fluid"
      :width="width"
      :rounded="getRounded()"
      :left="getAlign('left')"
      :center="getAlign('center')"
      :right="getAlign('right')"
    />
  </div>
</template>
