import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserDataService, ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";

import Vue from "vue";
import { Variable } from "@claimsgate/core-types";
export enum ActiveView {
  companyDetails = "companyDetails",
  uboDetails = "uboDetails",
}
export function getData(state: Vue) {
  return {
    blockProps: {
      searchCompany: { label: "Please search for the registered company this claim relates to" },
      tradingNameSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({
        label: "Is the companies trading name different to the registered company name?",
        options: ["Yes", "No"],
      }),
      tradingNameInput: UserVariableBlocksService.genBlockInputProps({
        label: "What is the company's trading name?",
        placeholder: "Company Trading Name",
      }),
      uboDataArray: {
        label: "Who are the ultimate beneficial owners of the company?",

        state: null,
      },
    },
    activeView: ActiveView.companyDetails,
    tradingNameIsDifferent: "",
    tradingName: "",
    uiToggles: {
      isProcessing: false,
      isCreateClaimError: false,
      complete: false,
    },
    claimDataService: undefined as ClaimDataService,
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    userId: undefined as string,
    claimId: state.$route.params.claimId as string,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(),
    funnelId: state.$route.params.funnelId as string,
    ubos: [] as Array<{ firstName: string; email: string; lastName: string }>,
    funnelVariables: [] as Array<Variable>,
    ubosVariable: undefined as Variable,
    infoModalService: new InfoModalService(state.$infoModal),
  };
}
