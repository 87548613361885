<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "BlockInfo",
  props: {
    state: {
      type: Boolean,
      default: null
    },
    invalidFeedback: {
      type: String,
      default: "This field is required"
    }
  }
});
</script>
<template>
  <b-form-invalid-feedback :state="state" v-if="state === false && invalidFeedback.length > 0">
    <p class="mb-0 text-danger">
      {{ invalidFeedback }}
    </p>
  </b-form-invalid-feedback>
</template>
