<script lang="ts">
import Vue from "vue";
import { Fragment } from "vue-frag";
import { ActiveView, getData } from "./data";
import { getProps } from "./props";
import BlockCompany from "@/components/shared/blocks/company/company.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDataArray from "@/components/shared/blocks/dataArray/dataArray.vue";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";
import { getClaimDataService } from "@/helpers/vue";
import { mappedComputed } from "./computed";
import { BlockKybInstance } from "./KybInstance";
import * as methods from "./methods";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";

export default Vue.extend({
  name: "BlockKyb",
  components: {
    Fragment,
    BlockCompany,
    BlockSingleSelect,
    BlockButton,
    BlockInput,
    BlockPadding,
    BlockDataArray,
    BlockInvalidFeedback,
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    ...mappedComputed,
  },
  props: {
    ...getProps(),
  },
  methods: {
    async nextButton() {
      await methods.nextButton(this);
    },
  },
  async mounted() {
    try {
      if (this.collectCompanyDetails === false) {
        this.activeView = ActiveView.uboDetails;
      } else {
        this.activeView = ActiveView.companyDetails;
      }
      this.userId = this.userService.getUserId();

      await getClaimDataService<BlockKybInstance>(this);

      this.userDataService = new UserDataService(this.userId);

      const { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);

      this.funnelVariables = funnelVariables;

      this.ubosVariable = this.funnelVariables.find((variable) => variable.field === "ubos");
    } catch (error) {
      await this.infoModalService.fire(
        "error",
        {
          title: msg.errors.unexpectedError.title,
          text: msg.errors.unexpectedError.text,
          confirmButtonText: "Redirect",
        },
        { route: this.$route }
      );

      await this.$router.push({ name: "Claims" });
    }
  },
});
</script>
<template>
  <Fragment>
    <!-- Page 1  view = companyInfo-->
    <Fragment v-if="activeView === 'companyDetails'">
      <!-- Company search -->
      <BlockCompany :label="blockProps.searchCompany.label" :padding="padding" :ref="'BlockCompany'" />
      <!-- Company name diff to reg name? -->
      <BlockSingleSelect
        :label="blockProps.tradingNameSingleSelect.label"
        :padding="padding"
        :answer.sync="tradingNameIsDifferent"
        :state="blockProps.tradingNameSingleSelect.state"
        :invalidFeedback="blockProps.tradingNameSingleSelect.invalidFeedback"
      ></BlockSingleSelect>
      <!-- If yes, company trading name -->
      <BlockInput
        v-bind="blockProps.tradingNameInput"
        :padding="padding"
        :answer.sync="tradingName"
        v-if="tradingNameIsDifferent === 'Yes'"
      ></BlockInput>
    </Fragment>

    <!-- Page 2 view = define UBOs -->
    <Fragment v-if="activeView === 'uboDetails'">
      <BlockPadding :padding="padding"></BlockPadding>
      <BlockDataArray
        :padding="padding"
        :label="blockProps.uboDataArray.label"
        :entityName="'UBO'"
        :storeAs="ubosVariable"
        :ref="'BlockDataArray'"
        :required="true"
      ></BlockDataArray>
    </Fragment>

    <template v-if="uiToggles.isCreateClaimError">
      <BlockPadding :padding="'10'"></BlockPadding>
      <BlockInvalidFeedback
        :invalidFeedback="'An error has occurred. Please confirm all details are accurate'"
        :state="false"
      />
    </template>
    <BlockPadding :padding="padding"></BlockPadding>
    <BlockButton
      :submitButtonDefault="true"
      @submit="nextButton()"
      :isProcessing="uiToggles.isProcessing"
      :disabled="uiToggles.isProcessing || uiToggles.complete"
    ></BlockButton>

    <!-- ? Back button to company search? -->
  </Fragment>
</template>
