import { Props } from "@/types";
import { blockCompanyStores } from "../company/props";
import { extraProps } from "@/helpers/ClaimsGate/blocks/inputProps";

type BlockCompanyStoresType = (typeof blockCompanyStores)[number];

const blockKybStores: Array<BlockCompanyStoresType | { type: string; name: string }> = [
  ...blockCompanyStores,
  { name: "ubos", type: "Array<T>" },
  { name: "tradingNameDiffersToRegistered", type: "string" },
  { name: "companyTradingName", type: "string" },
];
export function getProps() {
  return {
    stores: {
      type: Array,
      required: false,
      default: (): typeof blockKybStores => blockKybStores,
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<typeof blockCompanyStores>,
    padding: extraProps.padding,
    collectCompanyDetails: {
      type: Boolean,
      required: false,
      default: true,
      description: "If enabled, the user will be asked to provide company details",
    },
  };
}
