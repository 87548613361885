<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "BlockInfo",
  props: {
    info: {
      type: String,
      default: "",
    },
  },
});
</script>
<template>
  <div v-if="info">
    <p v-if="info.length > 0" class="mb-0 text-left">
      {{ info }}
    </p>
  </div>
</template>
