<script lang="ts">
import BlockInfo from "@/components/shared/blocks/info.vue";
import BlockLabel from "@/components/shared/blocks/label.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";
import { inputWrapperProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import Vue from "vue";
import { Props } from "@/types";
export default Vue.extend({
  name: "BlockInputWrapper",
  components: {
    BlockInfo,
    BlockLabel,
    BlockPadding,
    BlockInvalidFeedback,
  },
  props: {
    ...(inputWrapperProps() as Props.BlockInputWrapper),
  },
});
</script>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <BlockLabel :class="labelClass" :label="label" v-if="label && label.length > 0" />
    <BlockInfo :class="infoClass" :info="info" v-if="info && info.length > 0" />
    <slot></slot>
    <BlockInvalidFeedback
      :invalidFeedback="
        $te('blocks.invalidFeedback.' + invalidFeedback)
          ? $t('blocks.invalidFeedback.' + invalidFeedback)
          : invalidFeedback
      "
      :state="state"
      v-if="state === false"
    />
  </div>
</template>
